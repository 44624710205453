<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <Loading
      v-if="searching"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <!--<v-col cols="12">
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="itemsPerPage"
              :label="$t('lbl.itemsPerPage')"
              type="number"
              min="5"
              hide-details
              dense
              outlined
              @input="load()"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              :label="$t('btn.search')"
              single-line
              hide-details
              dense
              outlined
              @input="load()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-form class="multi-col-validation px-5 py-5">
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="text-right"
          >
            {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.currencies') | lowercase }}
          </v-col>
        </v-row>
      </v-form>
    </v-col>-->

    <v-col
      cols="12"
      md="2"
    >
      <v-menu
        v-model="menuFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFromDateFormatted"
            :label="$t('lbl.date')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateSearch"
          no-title
          :max="new Date().toISOString().substr(0, 10)"
          locale="es"
          :show-current="false"
          @input="menuFrom = false"
          @change="load"
        ></v-date-picker>
      </v-menu>
    </v-col>

    <v-spacer></v-spacer>

    <v-col
      v-if="(type_update === 'manual' && permisos.includes('comisiones:edit')) || permisos.includes('*')"
      cols="12"
      md="2"
    >
      <v-btn
        v-if="!editToday"
        color="primary"
        class="mb-4 me-3"
        @click="editTodayAction"
      >
        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
        <span>{{ $t('btn.edit') }}</span>
      </v-btn>
      <v-btn
        v-else
        color="error"
        class="mb-4 me-3"
        @click="cancelTodayAction"
      >
        <v-icon>{{ icons.mdiCancel }}</v-icon>
        <span>{{ $t('btn.cancele') }}</span>
      </v-btn>
    </v-col>
    <v-col
      v-if="editToday"
      cols="12"
      md="2"
    >
      <v-btn
        color="primary"
        class="mb-4 me-3"
        :loading="loading"
        @click="updateTodayAction"
      >
        <v-icon>{{ icons.mdiUpdate }}</v-icon>
        <span>{{ $t('btn.update') }}</span>
      </v-btn>
    </v-col>

    <v-col
      v-if="(type_update === 'automatic' && permisos.includes('comisiones:edit')) || permisos.includes('*')"
      cols="12"
      md="3"
    >
      <v-btn
        v-if="user.nivel <= 0"
        color="primary"
        class="mb-4 me-3"
        :loading="loading"
        :disabled="quota.remaining_quota_free === 0"
        @click="updateAutomatic"
      >
        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
        <span>{{ `${$t('btn.update')} (${quota.remaining_quota_free}/${quota.quota_free})` }}</span>
      </v-btn>
    </v-col>

    <v-col
      cols="12"
      md="12"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                width="5%"
                style="text-align: center;"
              ></th>
              <th
                class="text-uppercase"
                style="text-align: left;"
              >
                {{ $t('lbl.currency') }}
              </th>
              <th style="text-align: center;">
                <v-tooltip
                  v-if="permisos.includes('comisiones:create') || permisos.includes('*')"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="addCurrency"
                    >
                      <v-icon small>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </th>
              <th
                v-for="(date, indD) in dates"
                :key="indD"
                style="text-align: center; width: 160px;"
              >
                {{ $moment(date).format('DD/MMM') }}
              </th>
              <!--<th
                v-if="permisos.includes('comisiones:edit') || permisos.includes('*')"
                width="10%"
                class="text-uppercase"
                style="text-align: center;"
              >
                {{ $t('lbl.actions') }}
              </th>-->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(iten, index) in items"
              :key="index"
            >
              <td
                v-if="iten.name"
                style="text-align: center;"
              >
                <span v-if="iten.symbol">{{ iten.symbol }}</span>
              </td>
              <td v-if="iten.name">
                {{ iten.name }}
              </td>
              <td
                v-if="isEdit && !iten.name"
                colspan="2"
              >
                <v-select
                  v-model="iten.currency_id"
                  :items="currenciesFree"
                  :label="$t('lbl.selectCurrency')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  hide-details
                  class="py-2"
                  @change="confimAddCurrency(iten)"
                ></v-select>
              </td>
              <td style="text-align: center;">
                <v-tooltip
                  v-if="(!iten.required && permisos.includes('comisiones:destroy')) || permisos.includes('*')"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      x-small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteCurrency(iten)"
                    >
                      <v-icon small>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
              <fragment
                v-for="(date, indD) in dates"
                :key="indD"
              >
                <fragment
                  v-for="(exchange, indExch) in iten.dates"
                  :key="indExch"
                >
                  <td
                    v-if="$moment(exchange.date).format('DD/MM/Y') === $moment(date).format('DD/MM/Y')"
                    style="text-align: center;"
                  >
                    <span v-if="$moment(exchange.date).format('DD/MM/Y') === $moment(today).format('DD/MM/Y')">
                      <span v-if="editToday">
                        <v-text-field
                          v-model="exchange.value"
                          single-line
                          hide-details
                          dense
                          outlined
                          style="max-width: 150px;"
                          type="number"
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <span v-if="!exchange.value">
                          N/D
                        </span>
                        <span v-else>
                          {{ exchange.value }}
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="!exchange.value">
                        N/D
                      </span>
                      <span v-else>
                        {{ exchange.value }}
                      </span>
                    </span>
                  </td>
                </fragment>
              </fragment>
              <!--<td
                v-if="permisos.includes('comisiones:edit') || permisos.includes('*')"
                style="text-align: center;"
              >
                <v-menu
                  v-if="permisos.includes('comisiones:edit') || permisos.includes('*')"
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="permisos.includes('comisiones:edit')"
                      link
                      @click="editItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn icon>
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>-->
            </tr>

            <tr>
              <td
                colspan="2"
                style="text-align: center;"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.updatedBy') }}</b>
              </td>
              <td></td>
              <td
                v-for="(date, indD) in items[0].dates"
                :key="indD"
                style="text-align: center;"
              >
                <span v-if="date.user">
                  <span v-if="date.user.name"> {{ date.user.name }}<br /> </span>
                  <span v-if="date.user.time">
                    {{ `${$moment(date.date).format('DD MMM Y')}, ${date.user.time}` }}
                  </span>
                </span>
                <span v-else>N/D</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!--<v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              total-visible="5"
              @input="onPageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>-->
    </v-col>

    <SidebarDataFilter
      v-if="isSidebarDataFilterActive"
      v-model="isSidebarDataFilterActive"
      :currency="currency"
      :date-exchange="dateExchange"
    />

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${currency.code}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mt-5">
            {{ $t('msg.deleteItem', { item: currency.name }) }}
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="configDeleteCurrency()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiCancel,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiEmail,
  mdiFormatListText,
  mdiDotsVertical,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

import SidebarDataFilter from '../utils/SidebarDataFilter.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SidebarDataFilter,
    Loading,
  },
  data() {
    return {
      menuFrom: false,

      // dateSearch: new Date().toISOString().substr(0, 10), .toLocaleString('es-ES', { timeZone: 'America/Havana' })
      dateSearch: null,
      searching: false,
      isLoading: true,
      isTable: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiCancel,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiEmail,
        mdiFormatListText,
        mdiDotsVertical,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      isSidebarDataFilterActive: false,
      dateExchange: new Date(),
      search: '',
      today: null,
      type_update: null,
      dates: [],
      items: [],
      currency: {
        currency_id: null,
        name: '',
        code: '',
      },
      totalItems: 0,
      totalF: 0,
      editToday: false,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      loading: false,
      isDialogVisibleDelete: false,
      currencies: [],
      isEdit: false,
      quota: {
        quota_free: 0,
        remaining_quota_free: 0,
      },
      user: {},
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
      filtrosExport: state => state.app.filtrosExport,
    }),
    computedFromDateFormatted() {
      return this.dateSearch ? this.$moment(this.dateSearch).format('D-MMM-YY') : ''
    },
    currenciesFree() {
      const arr = []
      this.currencies.forEach(element => {
        let esta = false
        this.items.forEach(itemCurr => {
          if (element.code === itemCurr.code) {
            esta = true
          }
        })

        if (!esta) {
          arr.push(element)
        }
      })

      return arr
    },
  },
  mounted() {
    this.loadAll()
  },
  created() {
    this.profile()
    this.getCurrencies()
    this.getApiExchange()
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'setFiltrosExport']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.currencies = res.data.data
      })
    },
    getApiExchange() {
      const json = {
        type: 'exchanges_currencies',
      }
      this.axios
        .post('api_keys/quota', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            if (res.data.data) {
              this.quota = res.data.data
            }
          }
        })
    },
    load() {
      this.axios
        .get(
          `exchange_currency/currencies?active=true&page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.search}&dateSearch=${this.dateSearch}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data.currencies
            this.dates = res.data.data.dates
            this.today = res.data.data.today
            this.type_update = res.data.data.type_update

            /* this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
            this.totalF = res.data.meta.total - 1 */
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadAll() {
      this.axios
        .get('exchange_currency/currencies?active=true&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.dateSearch = res.data.data.today
          }
        })
        .finally(() => {
          this.load()
        })
    },
    onPageChange() {
      this.load()
    },

    updateTodayAction() {
      this.loading = true
      const arr = []
      this.items.forEach(element => {
        let value = null
        element.dates.forEach(date => {
          if (this.$moment(date.date).format('DD/MM/Y') === this.$moment(this.date).format('DD/MM/Y')) {
            value = date.value
          }
        })

        arr.push({
          value,
          currency_id: element.currency_id,
          currency_base: 'USD',
        })
      })

      this.axios
        .post(
          'exchange_currency',
          { currencies: arr },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.loading = false
          this.editToday = false
        })
    },

    editTodayAction() {
      this.editToday = true
    },
    cancelTodayAction() {
      this.editToday = false
    },

    updateAutomatic() {
      this.loading = true

      this.axios
        .post(
          'exchange_currency/run-api',
          { api: true },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            // this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.loading = false
          this.getApiExchange()
          this.load()
        })
    },

    editItem(iten) {
      this.currency = iten
      this.isSidebarDataFilterActive = true
    },

    addCurrency() {
      this.isEdit = true
      this.items.push({
        code: '',
        currency_id: null,
        dates: [],
        name: '',
        required: false,
        symbol: '',
      })
    },
    confimAddCurrency(iten) {
      if (iten.currency_id) {
        const json = {
          currency_id: iten.currency_id,
          status: 'actived',
        }
        this.axios
          .post('exchange_currency/status', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              // this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
          .finally(() => {
            this.isEdit = false
            this.load()
          })
      }
    },

    deleteCurrency(iten) {
      this.currency = iten
      this.isDialogVisibleDelete = true
    },
    configDeleteCurrency() {
      this.loading = true
      const json = {
        currency_id: this.currency.currency_id,
        status: 'desactived',
      }
      this.axios
        .post('exchange_currency/status', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.loading = false
          this.isDialogVisibleDelete = false
          this.currency = {
            currency_id: null,
            name: '',
            code: '',
          }
          this.load()
        })
    },
  },
}
</script>
