<template>
  <v-navigation-drawer
    :value="isSidebarDataFilterActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-filter-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('menu.comitions') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="cancelar"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <b>{{ $t('lbl.currency') }}</b>:
              <p>
                {{ `${currency.name} (${currency.code}) - ${currency.symbol}` }}
              </p>
              <p>
                <b>{{ $moment(dateExchange).format('d-MMM-y HH:mm') }}</b>
              </p>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="exchange"
                :label="$t('lbl.valueUSD')"
                single-line
                hide-details
                dense
                outlined
                type="number"
                prefix="$"
                suffix="USD"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="cancelar"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :loading="loadingBtn"
              >
                {{ $t('btn.apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
  model: {
    prop: 'isSidebarDataFilterActive',
    event: 'update:is-sidebar-data-filter-active',
  },
  props: {
    isSidebarDataFilterActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    currency: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    dateExchange: {
      type: String,
    },
  },
  data() {
    return {
      stepForm: null,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      loading: false,
      exchange: 1,
    }
  },
  computed: {
    ...mapState({
      filtrosExport: state => state.app.filtrosExport,
      loadingBtn: state => state.app.loadingBtn,
    }),
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'setFiltrosExport']),
    cancelar() {
      this.$emit('update:is-sidebar-data-filter-active', false)
    },
  },
}
</script>
